import React from 'react';
import styled from 'styled-components';
const Container = styled.div`
    margin-top: 0px;
    margin-bottom: 20px;
    display: block;
    width: calc(100% - 128px);
    margin-left: 64px;
    margin-right: 64px;
    @media screen and (max-width: 768px) {
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 60px;
        width: calc(100% - 48px);
    }
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
`;
const Heading = styled.div`
    margin-top: 12px;
    font-size: 36px;
    letter-spacing: -1.5px;
    line-height: 40px;
    text-align: center;
    color: white;
    font-family: 'Renogare-Regular';

    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
        color: white;
        margin-top: 0;
    }
`;
const SubHeading = styled.div`
    font-family: 'Neuzeit Grotesk';
    margin-top: 14px;
    font-size: 23px;
    line-height: 32px;
    letter-spacing: normal;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);

    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 32px;
        color: white;
        margin-top: 18px;
    }
`;
const FeatureContainer = styled.div`
    margin-top: 76px;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
        margin-top: 44px;
    }
`;
const Feature = styled.div`
    width: calc(25% - 41px);
    text-align: center;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px 20px 35px 20px;
    &:nth-child(4n) {
        border-right: none;
    }
    &:nth-child(n + 5) {
        padding: 35px 20px 20px 20px;
        border-bottom: none;
    }
    @media screen and (max-width: 767px) {
        width: calc(50% - 21px);
        padding: 10px;
        &:nth-child(2n) {
            border-right: none;
        }
        &:nth-child(n + 5) {
            padding: 10px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }
        &:nth-child(n + 7) {
            border-bottom: none;
        }
    }
`;
const FeatureImg = styled.div`
    display: flex;
    justify-content: center;
    @media screen and (max-width: 767px) {
    }
`;
const FeatureHeading = styled.div`
    font-family: 'Neuzeit Grotesk';
    font-size: 17px;
    line-height: 24px;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.9);
    text-align: center;
    margin-top: 16px;
    @media screen and (max-width: 767px) {
    }
`;
const FeatureDesc = styled.div`
    text-align: center;
    font-family: 'Neuzeit Grotesk';
    font-size: 15px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.7);
    @media screen and (max-width: 767px) {
    }
`;
export default function Lifestyle({ title, subtitle, description, features }) {
    return (
        <Container>
            <Content>
                <Heading>{title}</Heading>
                <SubHeading>{subtitle}</SubHeading>
            </Content>
            <FeatureContainer>
                {features.map(feature => {
                    return (
                        <Feature key={feature.title}>
                            <FeatureImg>
                                <img
                                    src={feature.image}
                                    alt={feature.title}
                                    width="60px"
                                    height="60px"
                                ></img>
                            </FeatureImg>
                            <FeatureHeading>{feature.title}</FeatureHeading>
                            <FeatureDesc>{feature.description}</FeatureDesc>
                        </Feature>
                    );
                })}
            </FeatureContainer>
        </Container>
    );
}
