import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: block;
    background-size: 100%;
    background: transparent;
    margin-left: 64px;
    margin-right: 64px;
    width: calc(100% - 128px);
    margin-bottom: 40px;
    margin-top: 40px;
    @media screen and (max-width: 767px) {
        margin-left: 24px;
        margin-right: 24px;
        width: calc(100% - 48px);
        margin-bottom: 100px;
    }
`;

const Heading = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 36px;
    text-align: center;
    color: white;
    line-height: 40px;
    letter-spacing: -1.5px;
    user-select: none;
    width: 640px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 768px) {
        width: 100%;
        font-size: 23px;
        line-height: 28px;
        letter-spacing: -0.92px;
        text-align: center;
    }
`;
const SubHeading = styled.div`
    margin-top: 26px;
    font-size: 17px;
    line-height: 24px;
    font-family: 'Neuzeit Grotesk';
    color: rgba(255, 255, 255, 0.7);
    letter-spacing: normal;
    user-select: none;
    text-align: center;
    width: 640px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 768px) {
        margin-top: 19px;
        width: 100%;
    }
`;
const Footer = styled.div`
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        margin-top: 32px;
    }
`;

const Desc = styled.div`
    user-select: none;
    width: 40%;
    @media screen and (max-width: 768px) {
        width: 48%;
    }
`;
const DescHeading = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.5px;
    text-align: left;
    color: white;
    @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;
        text-align: left;
    }
`;
const DescContent = styled.div`
    font-family: 'Neuzeit Grotesk';
    font-size: 15px;
    line-height: 20px;
    text-align: left;
    letter-spacing: normal;
    margin-top: 12px;
    color: rgba(255, 255, 255, 0.7);
    padding-right: 12px;
`;
const ImgContainer = styled.div`
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 44px;
    & > * {
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        padding: 0;
        margin-top: 24px;
        & > * {
        }
    }
`;

export default function CreatingUnique({ article, features }) {
    return (
        <Container>
            <Heading>{article.title}</Heading>
            <SubHeading>{article.description}</SubHeading>
            <ImgContainer>
                <img
                    width="100%"
                    height="100%"
                    src={article.image}
                    alt={article.title}
                    style={{ objectFit: 'contain' }}
                ></img>
            </ImgContainer>
            <Footer>
                {features.map((item, index) => {
                    return (
                        <Desc key={item.title}>
                            <DescHeading>{item.title}</DescHeading>
                            <DescContent>{item.description}</DescContent>
                        </Desc>
                    );
                })}
            </Footer>
        </Container>
    );
}
