import React from 'react';
import styled from 'styled-components';
const Container = styled.div`
    display: block;
    background-size: 100%;
    background: #fafafa;
    padding-top: 72px;
    padding-bottom: 90px;
    @media screen and (max-width: 767px) {
        padding-bottom: 92px;
        padding-top: 8px;
    }
`;
const IntroContainer = styled.div`
    margin: 0;
    display: flex;

    margin-left: 64px;
    margin-right: 64px;
    width: calc(100% - 128px);

    color: white;
    text-align: center;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        margin-left: 24px;
        margin-right: 24px;
        width: calc(100% - 48px);
        flex-direction: column;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    z-index: 0;
    margin-top: 0;
    @media screen and (max-width: 768px) {
        margin-top: 32px;
        width: 100%;
    }
`;
const Heading = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 36px;
    text-align: left;
    color: white;
    line-height: 40px;
    letter-spacing: -1.5px;
    user-select: none;
    color: #19171a;
    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.92px;
        text-align: center;
    }
`;
const Desc = styled.div`
    margin-top: 26px;
    font-size: 17px;
    font-family: 'Neuzeit Grotesk';
    text-align: left;
    letter-spacing: normal;
    line-height: 24px;
    color: rgba(25, 23, 26, 0.7);
    user-select: none;
    @media screen and (max-width: 768px) {
        font-size: 17px;
        font-family: 'Neuzeit Grotesk';
        text-align: left;
        letter-spacing: normal;
        line-height: 24px;
    }
`;
const ImgContainer = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    img {
        width: 300px;
        height: 100%;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        padding: 0;
        img {
            width: 160px;
        }
    }
`;

export default function DataPlatformIntro({ title, description, image }) {
    return (
        <Container>
            <IntroContainer>
                <ImgContainer>
                    <img src={image} alt="" style={{ objectFit: 'contain' }} />
                </ImgContainer>

                <Content>
                    <div>
                        <Heading>{title}</Heading>
                        <Desc>{description}</Desc>
                    </div>
                </Content>
            </IntroContainer>
        </Container>
    );
}
