import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: block;
    background-size: 100%;
    background: transparent;
    padding-bottom: 12px;
    padding-top: 12px;
    @media screen and (max-width: 767px) {
        padding-bottom: 24px;
        padding-top: 24px;
    }
`;
const DesktopContainer = styled.div`
    margin: 0;
    display: flex;

    margin-left: 64px;
    margin-right: 64px;
    width: calc(100% - 128px);

    color: white;
    text-align: center;
    flex-direction: ${props =>
        props.direction === 'right' ? 'row' : 'row-reverse'};
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        display: none;
    }
`;
const MobileContainer = styled.div`
    display: none;
    @media screen and (max-width: 767px) {
        display: flex;
        margin-left: 24px;
        margin-right: 24px;
        width: calc(100% - 48px);
        flex-direction: column;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    height: 100%;
    z-index: 0;
    margin-top: auto;
    margin-bottom: auto;
    @media screen and (max-width: 768px) {
        margin-top: 32px;
        width: 100%;
    }
`;
const Heading = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 36px;
    text-align: left;
    color: white;
    line-height: 40px;
    letter-spacing: -1.5px;
    user-select: none;
    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.92px;
        text-align: left;
    }
`;
const SubHeading = styled.div`
    margin-top: 26px;
    font-size: 17px;
    line-height: 24px;
    font-family: 'Neuzeit Grotesk';
    color: rgba(255, 255, 255, 0.7);
    letter-spacing: normal;
    user-select: none;
    text-align: left;
    @media screen and (max-width: 768px) {
        margin-top: 19px;
    }
`;
const Footer = styled.div`
    display: flex;
    margin-top: 44px;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        margin-top: 16px;
    }
`;
const ProgressBar = styled.div`
    border-radius: 1px;
    height: 4px;
    width: 100%;
    background: ${props =>
        props.active ? '#a68353' : 'rgba(255, 255, 255, 0.1)'};
`;
const Desc = styled.div`
    font-size: 17px;
    font-family: 'Neuzeit Grotesk';
    text-align: left;
    letter-spacing: normal;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.7);
    user-select: none;
    width: calc(50% - 1px);
    @media screen and (max-width: 768px) {
        font-size: 17px;
        font-family: 'Neuzeit Grotesk';
        text-align: left;
        letter-spacing: normal;
        line-height: 24px;
    }
`;
const DescContent = styled.div`
    font-family: 'Neuzeit Grotesk';
    font-size: 15px;
    line-height: 20px;
    text-align: left;
    letter-spacing: normal;
    margin-top: 12px;
    color: ${props =>
        props.active ? 'rgba(255, 255, 255, 0.9)' : 'rgba(255, 255, 255, 0.5)'};
    padding-right: 12px;
    padding-left: 0px;
    ${Desc}:last-child > & {
        padding-left: 12px;
        padding-right: 0px;
    }
`;
const ImgContainer = styled.div`
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 12px;
    & > * {
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        padding: 0;
        margin-top: 24px;
        & > * {
        }
    }
`;

export default function AudigentCompliments({ article, features }) {
    const [current, setCurrent] = useState(0);
    const onClickDesc = index => {
        setCurrent(index);
    };
    return (
        <Container>
            <DesktopContainer>
                <ImgContainer>
                    <img
                        src={features[current].image}
                        width="100%"
                        height="100%"
                        alt={features[current].title}
                        style={{ objectFit: 'contain' }}
                    ></img>
                </ImgContainer>

                <Content>
                    <div>
                        <Heading>{article.title}</Heading>
                        <SubHeading>{article.description}</SubHeading>
                        <Footer>
                            {features.map((item, index) => {
                                return (
                                    <Desc key={item.title}>
                                        <ProgressBar
                                            active={index === current}
                                        />
                                        <DescContent
                                            active={index === current}
                                            onClick={() => onClickDesc(index)}
                                        >
                                            {item.title}
                                        </DescContent>
                                    </Desc>
                                );
                            })}
                        </Footer>
                    </div>
                </Content>
            </DesktopContainer>
            <MobileContainer>
                <Heading>{article.title}</Heading>
                <SubHeading>{article.description}</SubHeading>
                <ImgContainer>
                    <img
                        src={features[current].image}
                        width="100%"
                        height="100%"
                        alt={features[current].title}
                        style={{ objectFit: 'contain' }}
                    ></img>
                </ImgContainer>
                <Footer>
                    {features.map((item, index) => {
                        return (
                            <Desc key={item.title}>
                                <ProgressBar active={index === current} />
                                <DescContent active={index === current}>
                                    {item.title}
                                </DescContent>
                            </Desc>
                        );
                    })}
                </Footer>
            </MobileContainer>
        </Container>
    );
}
