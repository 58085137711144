import React, { useState } from 'react';
import styled from 'styled-components';
import Ticker from 'react-ticker';
import PageVisibility from 'react-page-visibility';

import BackImg from 'assets/images/wave.png';
import BackMobileImg from 'assets/images/wave-mobile.png';
const Container = styled.div`
    padding: 54px 0 60px 0;
    display: block;
    background: url(${BackImg}) no-repeat bottom;
    background-size: 100% 100%;
    margin-bottom: 80px;
    @media screen and (max-width: 767px) {
        padding: 78px 0 140px 0;
        margin-bottom: 128px;
        background: url(${BackMobileImg}) no-repeat bottom;
        background-size: 100%;
    }
`;
const Content = styled.div`
    display: flex;
    margin-left: 64px;
    margin-right: 64px;
    width: calc(100% - 128px);
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        margin-left: 24px;
        margin-right: 24px;
        width: calc(100% - 48px);
        flex-direction: column;
    }
`;
const Left = styled.div`
    width: 40%;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;
const Desc = styled.div`
    width: 50%;
    color: rgba(255, 255, 255, 0.7);
    font-family: 'Neuzeit Grotesk';
    font-size: 17px;
    line-height: 24px;
    letter-spacing: normal;
    margin-top: 32px;
    text-align: left;
    @media screen and (max-width: 767px) {
        width: 100%;
        display: none;
    }
`;
const Top = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 13.4px;
    text-align: left;
    color: white;
    line-height: 24px;
    letter-spacing: normal;
    user-select: none;
    background: linear-gradient(0.25turn, #996b3d, #ccaa66, #ccaa66);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;
const Heading = styled.div`
    font-family: 'Renogare-Regular';
    text-align: ${props => (props.type === 0 ? 'left' : 'center')};
    width: ${props => (props.type === 0 ? 'auto' : '100%')};
    font-size: 36px;
    line-height: 40px;
    color: white;
    margin-top: 18px;
    letter-spacing: -1.5px;
    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.92px;
        text-align: left;
    }
`;
const Row1 = styled.div`
    width: 100%;
    margin-top: 53px;
`;
const Logo = styled.div`
    height: 128px;
    width: 200px;
    margin: 8px;
    @media screen and (max-width: 767px) {
        width: 125px;
        height: 80px;
        margin: 4px;
    }
`;
export default function DataScalePanel({
    name = 'EXPAND THE WAYS YOU MONETIZE DATA AT SCALE',
    title,
    description,
    type = 0,
    first_row,
    second_row,
}) {
    const [pageIsVisible, setPageIsVisible] = useState(true);

    const handleVisibilityChange = isVisible => {
        setPageIsVisible(isVisible);
    };
    return (
        <Container>
            <Content>
                {type === 0 ? (
                    <React.Fragment>
                        <Left>
                            <Top>{name}</Top>
                            <Heading type={type}>{title}</Heading>
                        </Left>
                        <Desc>{description}</Desc>
                    </React.Fragment>
                ) : type === 1 ? (
                    <Heading type={type}>{title}</Heading>
                ) : null}
            </Content>
            <Row1>
                <PageVisibility onChange={handleVisibilityChange}>
                    {pageIsVisible && (
                        <React.Fragment>
                            <Ticker speed={7} direction="toRight">
                                {({ index }) => (
                                    <Logo>
                                        <img
                                            src={
                                                first_row[
                                                    index % first_row.length
                                                ].image
                                            }
                                            alt="logo"
                                            style={{ objectFit: 'contain' }}
                                            width="100%"
                                            height="100%"
                                        />
                                    </Logo>
                                )}
                            </Ticker>
                            <Ticker speed={7}>
                                {({ index }) => (
                                    <Logo>
                                        <img
                                            src={
                                                second_row[
                                                    index % second_row.length
                                                ].image
                                            }
                                            alt="logo"
                                            style={{ objectFit: 'contain' }}
                                            width="100%"
                                            height="100%"
                                        />
                                    </Logo>
                                )}
                            </Ticker>
                        </React.Fragment>
                    )}
                </PageVisibility>
            </Row1>
        </Container>
    );
}
