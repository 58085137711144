import React from 'react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import GoldenButton from './../buttons/GoldenButton';

const Container = styled.div`
    display: block;
    background-size: 100%;
    background: ${props => props.backColor};
    @media screen and (max-width: 767px) {
        padding-bottom: 24px;
        padding-top: 24px;
    }
`;
const DesktopContainer = styled.div`
    margin: 0;
    display: flex;

    margin-left: 64px;
    margin-right: 64px;
    width: calc(100% - 128px);

    color: white;
    text-align: center;
    flex-direction: ${props =>
        props.direction === 'right' ? 'row' : 'row-reverse'};
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        display: none;
    }
`;
const MobileContainer = styled.div`
    display: none;
    @media screen and (max-width: 767px) {
        display: flex;
        margin-left: 24px;
        margin-right: 24px;
        width: calc(100% - 48px);
        flex-direction: column;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: ${props => (props.spacing === 1 ? 'calc(50% - 25px)' : '40%')};
    z-index: 0;
    margin-top: 0;
    @media screen and (max-width: 768px) {
        margin-top: 32px;
        width: 100%;
    }
`;
const Heading = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 36px;
    text-align: left;
    color: white;
    line-height: 40px;
    letter-spacing: -1.5px;
    user-select: none;
    margin-bottom: 26px;
    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.92px;
        text-align: left;
        margin-bottom: 0px;
    }
`;
const Desc = styled.div`
    margin-top: 6px;
    font-size: 17px;
    font-family: 'Neuzeit Grotesk';
    text-align: left;
    letter-spacing: normal;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.7);
    user-select: none;
    margin-bottom: 26px;
    white-space: pre-wrap;
    @media screen and (max-width: 768px) {
        font-size: 17px;
        font-family: 'Neuzeit Grotesk';
        text-align: left;
        letter-spacing: normal;
        line-height: 24px;
    }
`;
const ImgContainer = styled.div`
    width: ${props => (props.spacing === 1 ? 'calc(50% - 25px)' : '40%')};
    display: flex;
    justify-content: center;
    height: fit-content;
    & > * {
    }
    img {
        object-fit: contain;
    }
    @media screen and (max-width: 767px) {
        flex-direction: column;
        width: 100%;
        padding: 0;
        margin-top: 24px;
        margin-bottom: 24px;
        & > * {
        }
    }
`;

export default function Halo({
    title,
    subtitle,
    description,
    image,
    direction,
    spec,
    backColor = 'transparent',
    spacing = 0,
    button,
    url,
}) {
    return (
        <Container backColor={backColor}>
            <DesktopContainer direction={direction}>
                <ImgContainer spacing={spacing}>
                    <LazyLoadImage
                        alt={title}
                        effect="blur"
                        src={image} // use normal <img> attributes as props
                        width={'100%'}
                        height={'auto'}
                        style={{ objectFit: 'contain' }}
                    />
                </ImgContainer>

                <Content spacing={spacing}>
                    <div>
                        <Heading>{title}</Heading>
                        <Desc>{description}</Desc>
                        <GoldenButton type="url" url={url}>
                            {button}
                        </GoldenButton>
                    </div>
                </Content>
            </DesktopContainer>
            <MobileContainer>
                <Heading>{title}</Heading>
                <ImgContainer>
                    <img src={image} width="100%" alt={title}></img>
                </ImgContainer>
                <Desc>{description}</Desc>
                <GoldenButton type="url" url={url}>
                    {button}
                </GoldenButton>
            </MobileContainer>
        </Container>
    );
}
