import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Controller, Scene } from 'react-scrollmagic';

import Layout from '../layout/main';
import SEOComponent from '../components/seo';
import Hero4 from '../components/hero4';
import DataPlatformIntro from '../components/dataPlatformIntro';
import PageTabs from '../components/pageTabs';
import Lifestyle from '../components/lifestyle';
import Article from '../components/article';
import Testimonial from 'components/testimonial';
import DataScalePanel from 'components/dataScalePanel';
import MonetizationScale from '../components/accordianPanel';
import FeaturesPanel from '../components/featuresPanel';
import AudigentCompliments from '../components/audigentCompliments';
import CreatingUnique from '../components/creatingUnique';
import Halo from '../components/halo';
import CTAComponent from '../components/cta';
import { Wave3, Wave1, Wave2 } from '../components/wave';

import Wave2Img from './../assets/images/wave2.png';
import Wave2MobileImg from './../assets/images/wave2-mobile.png';

const tabs = [
    'AUDIENCE IDENTIFICATION',
    'DATA MONETIZATION',
    'Audience Extension',
];

const TabSection = styled.div`
    .tab-spacer {
        padding: 0 !important;
        min-width: auto !important;
    }
    margin-bottom: 72px;
    @media screen and (max-width: 767px) {
        margin-bottom: 36px;
    }
`;

const TabPanel = styled.div`
    padding: 24px 64px 64px 64px;
    width: calc(100% - 128px);
    position: ${props => (props.stickyPosition ? 'fixed' : 'static')};
    top: 0;
    background: #1d1b1e;
    z-index: 9;
    @media screen and (max-width: 767px) {
        padding: 16px 24px 24px 24px;
        width: calc(100% - 48px);
    }
`;

const TabHeading = styled.div`
    font-family: 'Neuzeit Grotesk';
    margin-top: 64px;
    margin-bottom: 0px;
    padding: 0 64px;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: normal;
    color: white;
    text-align: center;
    @media screen and (max-width: 767px) {
        margin-top: 44px;
        margin-bottom: 0px;
        padding: 0 24px;
    }
`;
const TabContent = styled.div`
    margin-top: ${props => (props.stickyPosition ? '316px' : '0px')};
`;
const Tab3 = styled.div`
    margin-top: 20px;
    @media screen and (max-width: 767px) {
        margin-top: 8px;
    }
`;
const Wave32 = styled.div`
    height: 120px;
    & > * {
        margin-top: -60px;
        width: 100%;
        position: absolute;
        @media screen and (max-width: 767px) {
            margin-top: 0;
            position: relative;
        }
    }
    @media screen and (max-width: 767px) {
        height: auto;
    }
`;
export default function DataPlatformPage({ data }) {
    const [currentTab, setCurrentTab] = useState(tabs[0]);

    const {
        seo_content,
        hero,
        data_platform_intro,
        halo,
        lifestyle,
        audience_articles,
        data_partners,
        data_monetization_features,
        audience_features,
        audience_article,
        audience_section,
        testimonials,
        book_now,
    } = data.butterPage;

    // console.log('testimonials', testimonials);
    return (
        <Layout menu="FOR PUBLISHERS">
            <SEOComponent
                title="Data Platform"
                image={seo_content.sharing_image}
                description={seo_content.seo_description}
            />
            <Hero4 {...hero[0]} type={1}></Hero4>
            <DataPlatformIntro {...data_platform_intro} />
            {/*      
        <VisibilitySensor
          onChange={handleVisibilityChange}
          partialVisibility={false}
        >
          <div style={{ height: 1 }}></div>
        </VisibilitySensor>
      */}
            <TabSection>
                <TabHeading>
                    Audigent's tech stack solves three of the biggest hurdles
                    faced by publishers
                </TabHeading>
                <Controller>
                    <Scene
                        duration={0}
                        pin={{ pushFollowers: true }}
                        pinSettings={{ spacerClass: 'tab-spacer' }}
                        enabled={true}
                        triggerHook={'onLeave'}
                    >
                        <TabPanel>
                            <PageTabs
                                values={tabs}
                                onClick={e => setCurrentTab(e)}
                                current={currentTab}
                                type={1}
                            />
                        </TabPanel>
                    </Scene>

                    <TabContent>
                        {currentTab === tabs[0] ? (
                            <React.Fragment>
                                <Halo {...halo[0]} />
                                <Wave3 />
                                <Lifestyle {...lifestyle[0]} />
                                <Wave2 />
                                <Article
                                    {...audience_articles[0]}
                                    spec={[
                                        '200+ Films',
                                        '150+ Actors',
                                        '190+ TV Shows',
                                    ]}
                                    direction={'right'}
                                ></Article>
                                <Wave1 />
                                <Article
                                    {...audience_articles[1]}
                                    direction={'left'}
                                ></Article>
                                <Wave2 />
                                <Article
                                    {...audience_articles[2]}
                                    spec={[
                                        'Over 240 Individual Athletes',
                                        '24 NFL Teams',
                                        '20 NBA Teams',
                                        '14 MLB teams',
                                        'NASCAR',
                                        'Soccer',
                                        'NHL',
                                        'Gaming Platforms and Consoles',
                                    ]}
                                    direction={'right'}
                                ></Article>
                                <Wave1 />
                                <Article
                                    {...audience_articles[3]}
                                    direction={'left'}
                                ></Article>
                                <Wave2 />
                            </React.Fragment>
                        ) : currentTab === tabs[1] ? (
                            <React.Fragment>
                                <DataScalePanel
                                    {...data_partners[0]}
                                    type={0}
                                />
                                <MonetizationScale
                                    {...data_monetization_features[0]}
                                />
                            </React.Fragment>
                        ) : (
                            <Tab3>
                                <FeaturesPanel
                                    {...audience_features[0]}
                                    desktopImg={Wave2Img}
                                    mobileImg={Wave2MobileImg}
                                    type={1}
                                />
                                <Article
                                    {...audience_article}
                                    direction="right"
                                />
                                <Wave32>
                                    <Wave1 />
                                </Wave32>

                                <AudigentCompliments {...audience_section[0]} />
                                <Wave32>
                                    <Wave2 />
                                </Wave32>

                                <CreatingUnique {...audience_section[1]} />
                            </Tab3>
                        )}
                    </TabContent>
                    {!!testimonials && testimonials.length > 0 ? (
                        <Testimonial {...testimonials[0]} />
                    ) : (
                        <div />
                    )}
                </Controller>
            </TabSection>

            <CTAComponent {...book_now[0]} />
        </Layout>
    );
}

export const dataPlatformPageQuery = graphql`
    query {
        butterPage(slug: { eq: "data-platform" }) {
            seo_content {
                seo_description
                sharing_image
            }
            hero {
                title
                description
                desktop_image
                mobile_image
                mask_image
                buttons {
                    type
                    title
                    theme
                    url
                }
            }
            data_platform_intro {
                title
                description
                image
            }
            halo {
                title
                description
                image
                button
                url
            }
            lifestyle {
                title
                subtitle
                description
                features {
                    title
                    description
                    image
                }
            }

            audience_articles {
                title
                subtitle
                description
                image
            }

            data_partners {
                name
                title
                description
                first_row {
                    title
                    image
                }
                second_row {
                    title
                    image
                }
            }

            data_monetization_features {
                title
                features {
                    title
                    description
                    image
                    button
                    url
                }
            }

            audience_features {
                title
                description
                features {
                    title
                    image
                }
            }
            audience_article {
                title
                description
                image
            }
            audience_section {
                article {
                    title
                    description
                    image
                }
                features {
                    title
                    description
                    image
                }
            }

            # testimonials {
            #   title
            #   testimonials {
            #     author_name
            #     author_job
            #     author_image
            #     company_logo
            #     comment
            #   }
            # }
            book_now {
                button_title
                title
            }
        }
    }
`;
